
import Vue from 'vue'
import Component from 'vue-class-component'
import TheFooter from '@/views/layouts/TheFooter.vue'
import { UserRoles } from '@/typings/UserTypings'
import { mapGetters } from 'vuex'
import Auth from '@/apis/AuthApi'
// import Axios from 'axios'

@Component({
  name: 'Authentication',
  components: {
    TheFooter
  },
  computed: {
    ...mapGetters({
      userRole: 'auth/userRole'
    })
  }
})

export default class Authentication extends Vue {
}

